import React, { useState } from 'react';
import './ProductsList.less';
import Dashboard from './Dashboard';
import { Card, Row, Col, Form, Input, Select, Button, Table, Checkbox, Radio, Tooltip, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import productApi from '../servapi/product';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import shopApi from '../servapi/shop';

const RadioGroup = Radio.Group;

const RadioOptions = ({ onPushStore }) => {
  const { t } = useTranslation();
  return (

    <RadioGroup className="ant-radio-group ant-radio-group-outline">
      <Radio.Button value="a" disabled>
        <span>{t("productList.btns.selectedProduct")}</span>
      </Radio.Button>
      <Radio.Button value="push" onClick={onPushStore}>
        <span>{t("productList.btns.pushStore")}</span>
      </Radio.Button>
      <Radio.Button value="update" disabled>
        <span>{t("productList.btns.refreshData")}</span>
      </Radio.Button>
      <Radio.Button value="delete" disabled>
        <span>{t("productList.btns.deleteSeletedProduct")}</span>
      </Radio.Button>
    </RadioGroup>
  );
};

function transformData(json) {
  return {
    key: json.id.toString(),
    name: json.productName,
    cost: json.lowestPrice ? json.lowestPrice.toString() : 'N/A',
    source: json.platform,
    productUrl: json.productUrl,
    spuCode: json.spuCode,
    price: json.highestPrice,
    platform: json.platform || 'N/A',
    pushStatus: json.pushState ? 'Pushed' : 'Not Pushed',
    inventoryStatus: json.inventoryState ? 'In Stock' : 'Out of Stock',
    updateTime: json.updateTime || 'N/A',
  };
}

const ProductsTable = () => {
  const [pageData, setPageData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    productApi.getSpuProducts({
      language: i18n.language
    })
      .then(pageData => {
        setPageData(pageData);
      })
      .catch(console.log);

  }, []);

  const onSelectChange = (record) => {
    const key = record.key;
    const selectedIndex = selectedRowKeys.indexOf(key);
    let newSelectedRowKeys = [...selectedRowKeys];

    if (selectedIndex === -1) {
      newSelectedRowKeys.push(key);
    } else {
      newSelectedRowKeys.splice(selectedIndex, 1);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onToggleAllRows = (checked) => {
    const newSelectedRowKeys = checked ? data.map((record) => record.id.toString()) : [];
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePushStore = () => {
    if (selectedRowKeys.length <= 0) {
      return;
    }

    const hide = message.loading("商品正在推送", 0);

    shopApi.pushProductsToShop(2, {
      ids: selectedRowKeys
    })
      .then(() => {
        hide();
        message.success('商品推送完成')
      })
      .catch(console.log)
  }



  const data = pageData?.content || [];
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
          checked={selectedRowKeys.length === data.length}
          onChange={(e) => onToggleAllRows(e.target.checked)}
        />
      ),
      key: 'selection',
      dataIndex: 'selection',
      render: (_, record) => (
        <Checkbox
          onChange={() => onSelectChange(record)}
          checked={selectedRowKeys.includes(record.key)}
        />
      ),
      width: '5%',
    },
    {
      title: t("productList.table.name"),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={record.productUrl}
            alt={text}
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Link to={`/product/detail/${record.spuCode}`} target="_blank">
            <span style={{ color: '#000' }} >{text}</span>
          </Link>

        </div>
      ),
    },
    {
      title: t("productList.table.price"),
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      render: (_, record) => (
        <span>{t("common.currency.symbol")}{record.price}</span>
      )
    },
    {
      title: (
        <Tooltip title={t("productList.table.pushStatus")}>
          {t("productList.table.pushStatus")}
          <QuestionCircleOutlined style={{ marginLeft: '8px' }} />
        </Tooltip>
      ),
      dataIndex: 'pushStatus',
      key: 'pushStatus',
      width: '10%',
    },
    {
      title: `${t("productList.table.updateTime")}(UTC)`,
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '15%',
    },
    {
      title: t("productList.table.action"),
      key: 'action',
      fixed: 'right',
      render: () => <a>Delete</a>,
      width: '10%',
    },
  ];



  return (
    <>
      <div className="patch flex-center-vertical">
        <RadioOptions onPushStore={handlePushStore} />
      </div>
      <div className="antd-table-wrapper table w-full">
        <Table
          columns={columns}
          dataSource={data.map(d => transformData(d))}
          // scroll={{ x: 'max-content' }}
          pagination={false}
          rowKey="id"
          className="products-table"
        />
      </div>
    </>

  );
}


export default props => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page_head">
        <div id="page_head_content" style={{ height: '72px' }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
            <Col span={12}>
              <div className="flex-center-vertical">
                <h3 className="ant-typography ant-typography-ellipsis ant-typography-single-line ant-typography-ellipsis-single-line text-24" style={{ margin: 0, lineHeight: '72px', minHeight: '72px', padding: '0px 8px', flexGrow: 1 }}>
                  {t("productList.title")}
                </h3>
              </div>
            </Col>
            {/* <Col span={12} style={{ textAlign: 'right' }}>
              <Button href="" style={{ marginRight: 15 }}>
                批量导入商品
              </Button>
              <Button type="primary" href="" className="add_self_product_button">
                添加自定义商品
              </Button>
            </Col> */}
          </Row>
        </div>
      </div>
      <div className='inner-content relative px-[16px]' style={{ paddingTop: '82px', paddingBottom: '32px' }}>
        <div className="saved-products">
          <Card>
            <div className="ant-card-body">
              <Form className="ant-legacy-form ant-legacy-form-inline block-item">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.productName")} className="ant-legacy-form-item-label-left">
                      <Input placeholder={t("productList.search.productPlaceHolder")} id="keyword" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.source")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="platform">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.pushStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="pushState">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={t("productList.search.inventoryStatus")} className="ant-legacy-form-item-label-left">
                      <Select placeholder={t("productList.search.all")} id="inventoryState">
                        {/* Select options here */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16} style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="button" style={{ marginRight: 8 }}>{t("productList.search.searchBtn")}</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="button">{t("productList.search.clearBtn")}</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </div>
          </Card>
          <Card className="products-table mt-[16px]">
            <ProductsTable />
          </Card>
        </div>
      </div>
    </>
  )
}