import { create } from "../util/request";
import i18n from 'i18next';
import qs from 'qs';

const api = create({ baseURL: ''})

class ShopApi {

  async getShops(params) {
    return await api.get(`/api/v1/shops?${qs.stringify(params)}`);
  }

  async getShopList() {
    return await api.get(`/api/v1/shops_list`);
  }

  async getShop(id) {
    return await api.get(`/api/v1/shops/${id}`);
  }

  async getShopProducts(id, params) {
    return await api.get(`/api/v1/shops/${id}/shop_products?${qs.stringify(params, { arrayFormat: 'repeat' })}`);
  }

  async deleteShopProduct(id, shopProductId) {
    return await api.delete(`/api/v1/shops/${id}/shop_products/${shopProductId}`);
  }

  async pushProductsToShop(id, params) {
    return await api.post(`/api/v1/shops/${id}/shop_products/push`, params);
  }

  async addShopProducts(id, params) {
    return await api.post(`/api/v1/shops/${id}/shop_products`, params);
  }

  async addProductsToShops(params) {
    return await api.post(`/api/v1/shops/shop_products`, params);
  }

  async updateShopProductCategories(id, shopProductId, params) {
    return await api.post(`/api/v1/shops/${id}/shop_products/${shopProductId}/categories`, params);
  }

  async confirmSaleOrder(id, saleOrderId) {
    return await api.post(`/api/v1/shops/${id}/sale_orders/${saleOrderId}/confirm`);
  }

  async startAuth(shopUrl) {
    return await api.get(`/api/v1/shops/start-auth?shopUrl=${encodeURIComponent(shopUrl)}`)
  }

  async getShopCategories(id, params) {
    return await api.get(`/api/v1/shops/${id}/categories?${qs.stringify(params)}`)
  }

  async getAllShopCategories(id) {
    return await api.get(`/api/v1/shops/${id}/categories_list`)
  }

  async getShopCategory(categoryId) {
    return await api.get(`/api/v1/shops/categories/${categoryId}`);
  }

  async createShopCategory(id, params) {
    return await api.post(`/api/v1/shops/${id}/categories`, params);
  }

  async updateShopCategory(id, categoryId, params) {
    return await api.post(`/api/v1/shops/${id}/categories/${categoryId}`, params);
  }

  async deleteShopCategory(id, categoryId) {
    return await api.delete(`/api/v1/shops/${id}/categories/${categoryId}`);
  }

  async resyncShopCategories(id) {
    return await api.post(`/api/v1/shops/${id}/categories/resync`);
  }

  async updateShopConfig(id, params) {
    return await api.post(`/api/v1/shops/${id}/config`, params);
  }
}

const shopApi = new ShopApi();
export default shopApi;